import { h, render, Fragment } from "preact";
import Footer from "js/components/design/Footer.js";
import Header2 from "js/components/design/Header2.js";

const AccountLayout = ({ children }) => {
    return (
        <Fragment>
            <Header2 />
            {children}
            <Footer />
        </Fragment>
    );
};

export default AccountLayout;
