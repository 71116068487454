import { h, render, Fragment } from "preact";
import { useState } from "preact/hooks";
import { Popover, Transition, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
    ChevronDownIcon,
    ArchiveBoxIcon,
    ArrowRightCircleIcon,
    DocumentDuplicateIcon,
    HeartIcon,
    PencilSquareIcon,
    TrashIcon,
    UserPlusIcon
} from "@heroicons/react/solid";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import useAuth from "js/components/User/useAuth.js";
import "css/pages/navbar.css";
const links = [
    { href: "/account-settings", label: "Account settings" },
    { href: "/support", label: "Support" },
    { href: "/license", label: "License" },
    { href: "/sign-out", label: "Sign out" },
];

export default function Header() {
    const auth = useAuth();
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    return (
        <Popover>
            <div className="navbar_acc font-heebo border-b-2 border-gray-200">
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a className="w-[200px] h-[100px]" href="/">
                                {/* <span className="font-semibold text-logoblue-200">TODAY</span><span className="font-semibold text-lightorange-100">CASH</span> */}
                                <span className="sr-only">Today Cash</span>
                                <img
                                    className="w-auto pt-[30px]"
                                    src="/public/images/tc_logo_nobg.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-lightorange-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-logoblue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <Popover.Group
                            as="nav"
                            className="hidden md:flex md:space-x-5 lg:space-x-10"
                        >
                            <a
                                href="/rates-and-terms"
                                className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                            >
                                Rates & Terms
                            </a>
                            <a
                                href="/faq"
                                className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                            >
                                FAQ
                            </a>
                            <a
                                href="/contact-us"
                                className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                            >
                                Contact
                            </a>
                        </Popover.Group>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            {(auth.loading || !auth.user) && (
                                <Fragment>
                                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                        <a
                                            href="/login"
                                            className="whitespace-nowrap text-base font-inter font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            {" "}
                                            Sign in{" "}
                                        </a>
                                        <a
                                            href="/apply-now"
                                            className="ml-8 whitespace-nowrap inline-flex items-center justify-center font-inter px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white transition ease-in-out delay-120 bg-lightorange-100 hover:bg-white duration-300 hover:text-lightorange-100 hover:border-lightorange-100"
                                        >
                                            {" "}
                                            Apply Now{" "}
                                        </a>
                                    </div>
                                </Fragment>
                            )}
                            {!auth.loading && auth.user && (
                                <Fragment>
                                    <div className="flex flex-row justify-center items-center">
                                        {/* flex flex-row justify-center items-center */}
                                        {/* <div className="relative">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="relative h-8 w-8 text-gray-400">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                                            </svg>
                                            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
                                                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
                                            </span>
                                        </div>
                                        <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-200 ml-[2rem]">
                                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-400">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span> */}

                                        <a
                                            href="/account"
                                            className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 mr-2"
                                        >
                                            My Account
                                        </a>
                                        <a
                                            onClick={() => {
                                                auth.sign_out(() => {
                                                    window.location = "/";
                                                });
                                            }}
                                            className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 ml-2"
                                        >
                                            Logout
                                        </a>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-white divide-y-2 divide-logoblue-200">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <a href="/">
                                                <div className="flex items-center">
                                                    <img
                                                        className="w-6/12"
                                                        src="/public/images/tc_logo_nobg.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-lightorange-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-white hover:bg-logoblue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">
                                                    Close menu
                                                </span>
                                                <XIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <div className="flex flex-col p-4">
                                        <a
                                            href="/rates-and-terms"
                                            className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            Rates & Terms
                                        </a>
                                        <a
                                            href="/faq"
                                            className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            FAQ
                                        </a>
                                        <a
                                            href="/contact-us"
                                            className="text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                        >
                                            Contact
                                        </a>
                                        {(auth.loading || !auth.user) && (
                                            <Fragment>
                                                <a
                                                    href="/login"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Sign in
                                                </a>
                                                <a
                                                    href="/apply-now"
                                                    className="whitespace-nowrap text-base font-medium text-logoblue-200"
                                                >
                                                    Apply Now
                                                </a>
                                            </Fragment>
                                        )}
                                        {!auth.loading && auth.user && (
                                            <Fragment>
                                                <a
                                                    href="/account"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    My Account
                                                </a>
                                                <a
                                                    onClick={() => {
                                                        auth.sign_out(() => {
                                                            window.location =
                                                                "/";
                                                        });
                                                    }}
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Logout
                                                </a>
                                            </Fragment>
                                        )}
                                    </div>
                                    {/* <div className="mt-6 flex justify-evenly">
                                        {(auth.loading || !auth.user) && (
                                            <Fragment>
                                                <a
                                                    href="/login"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Sign in
                                                </a>
                                                <a
                                                    href="/apply-now"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200"
                                                >
                                                    Apply Now
                                                </a>
                                            </Fragment>
                                        )}
                                        {!auth.loading && auth.user && (
                                            <Fragment>
                                                <a
                                                    href="/account"
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 mr-2"
                                                >
                                                    My Account
                                                </a>
                                                |
                                                <a
                                                    onClick={() => {
                                                        auth.sign_out(() => {
                                                            window.location =
                                                                "/";
                                                        });
                                                    }}
                                                    className="whitespace-nowrap text-base font-medium text-lightorange-100 hover:text-logoblue-200 ml-2"
                                                >
                                                    Logout
                                                </a>
                                            </Fragment>
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </div>
            </div>
        </Popover>
    );
}
