import { h, render, Fragment } from "preact";
import Footer from "js/components/design/Footer.js";
import Header from "js/components/design/Header.js";
// import Header2 from "js/components/design/Header2.js";
// import Legal from "js/components/design/Legal.js";

const MainLayout = ({ children }) => {
    return (
        <Fragment>
            <Header />
            {children}
            <Footer />
        </Fragment>
    );
};

export default MainLayout;
